/*
====================================================
                    MAIN
====================================================
*/

.cookiebar-main-container{
    width: 100%;
    min-height: 25%;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 50px 70px;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    background-color: rgb(34,34,34);
}

.cookiebar-text-containter{
    width: auto;
    height: auto;
    /* background-color: khaki; */
}
.cookiebar-title-containter{
    margin: auto 20px;
    padding: 0px;
    font-size: 30px;
    font-family: LaudanumSolid;
    color: rgb(233,219,186)
}
.cookiebar-subtitle-containter{
    margin: auto 20px;
    padding: 0px;
    font-size: 15px;
    font-family: PreloLight;
    color: rgb(255, 255, 255);
}
.cookiebar-subtitle-containter a{

    text-decoration: underline;
    color: rgb(255, 255, 255);
}


.cookiebar-action-containter{
    width: 200px;
    height: auto;  
    margin-left: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.cookiebar-action-button{
    width: 150px;
    padding: 10px 0px;
    border: 2px solid rgb(233,219,186);
    text-align: center;
    font-size: 18px;
    font-family: LaudanumSolid;
    color: rgb(233,219,186);
    transition: all 0.2s linear;
    cursor: pointer;
}
 
.cookiebar-action-button:hover { 
    color: rgb(34,34,34);
    background-color: rgb(233,219,186);
}


@media (min-width: 481px) and (max-width: 768px) {
    .cookiebar-main-container{
        flex-flow: column;
        padding: 40px 20px;
        text-align: center;
    }
    .cookiebar-action-containter{
        margin-left: 0px;
        margin-top: 30px;
    }
}

@media (min-width: 320px) and (max-width: 480px) {
    .cookiebar-main-container{
        flex-flow: column;
        padding: 40px 20px;
        text-align: center;
    }
    .cookiebar-action-containter{
        margin-left: 0px;
        margin-top: 30px;
    }
}