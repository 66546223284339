@font-face {
  font-family: 'Laudanum';
  src: url('../fonts/Laudanum.otf') format('opentype');
}
@font-face {
  font-family: 'LaudanumSolid';
  src: url('../fonts/LaudanumSolid.otf') format('opentype');
}
@font-face {
  font-family: 'PreloLight';
  src: url('../fonts/PreloLight.otf') format('opentype');
}
@font-face {
  font-family: 'PreloMedium';
  src: url('../fonts/PreloMedium.otf') format('opentype');
}
@font-face {
  font-family: 'PreloSlabMedium';
  src: url('../fonts/PreloSlabMedium.otf') format('opentype');
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* --scroll-behavior: smooth;
  scroll-behavior: smooth; */
  background-color: rgb(233,219,186) !important; 
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar {
  width: 0px;  /* Remove scrollbar space */
  background: transparent;  /* Optional: just make scrollbar invisible */
}
@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon.eot');
  src:  url('../fonts/icomoon.eot') format('embedded-opentype'),
    url('../fonts/icomoon.ttf') format('truetype'),
    url('../fonts/icomoon.woff') format('woff'),
    url('../fonts/icomoon.svg') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  font-size: 30px;
  color: rgb(233,219,186);

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.carousel-indicators li{
  background-color: rgb(233,219,186) !important;
}

.icon-left:before {
  content: "\e900";
}
.icon-right:before {
  content: "\e901";
}
