.age-conatiner{
    width: 100%;
    display: flex;
    position: fixed;
    z-index: 999999;
}
.age-conatiner.top{
    align-items: flex-end;
    justify-content: center;
    height: 60%;
    background-color: rgb(233,219,186) !important; 
}

.age-conatiner.bottom{
    justify-content: center;
    height: 40%;
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: rgb(34, 34, 34);
}
.age-conatiner.bottom img{
    width: 80vmax;
    height: auto;
    object-fit: contain;
    position: relative;
    top: -100px
}

.age-selection-container{
    max-width: 500px;
    padding: 20px;
    height: auto;
    border-radius: 5px;
    border: 5px solid rgb(187,174,144);
}
.age-selection-subtitle{
    margin: 20px auto 6px auto;
    font-size: 3vmax;
    line-height: 3vmax;
    text-align: center;
    font-family: LaudanumSolid;
    color: rgb(146,134,105);
}
.age-selection-title{
    margin: 0px auto 7px auto;
    font-size: 4.5vmax;
    text-align: center;
    line-height: 4vmax;
    font-family: LaudanumSolid;
    color: rgb(34, 34, 34);
}
.age-selection-warning{
    margin: 0px auto 10px auto;
    font-size: 1.7vmax;
    text-align: center;
    line-height: 3rem;
    font-family: LaudanumSolid;
    color: rgb(34, 34, 34);
}
.age-selection-option{
    width: 100%;
    height: 80px;
    margin: 50px auto 50px auto;
    padding-bottom: 50px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.age-selection-button{
    width: 90px;
    height: auto;
    padding-top: 5px;
    border-radius: 3px;
    border: 3px solid rgb(187,174,144);
    font-size: 30px;
    text-align: center;
    font-family: LaudanumSolid;
    color: rgb(34, 34, 34);
    cursor: pointer;
    transition: all 0.2s linear;
}

.age-selection-button:hover{
    border: 3px solid rgb(34, 34, 34);
    color: rgb(187,174,144);
    background-color: rgb(34, 34, 34);
    
}

