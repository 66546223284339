/*
====================================================
                    MAIN
====================================================
*/

  .section-container {
    width: 100vw;
    /* scroll-snap-align: start; */
    display: flex;
    flex-flow: column;
    justify-content: space-evenly;
    align-items: center;
    background-color: rgb(233,219,186)
  }

  /*
  ====================================================
                      HEADER
  ====================================================
  */
  .header-container {
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    background-color:  rgb(233,219,186);
    /*DEBUG*/
    /* background-color: darkslateblue; */
  }
  .header-container img{
     width: auto;
     height: 60%;
  }
  .header-social-container {
    width: auto;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .header-social-button{
    width: 34px;
    height: 34px;
    margin: 5px;
    display: flex;
    position: relative;
    border-radius: 50%;
    border: 1px solid rgb(34, 34, 34);
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.2s linear;
  }
  .header-social-icon{
    margin: auto;
    font-size: 17px;
    color: rgb(34, 34, 34);
    transition: all 0.2s linear;
  }
  .header-social-button:hover {
    background-color: rgb(34, 34, 34);
  }
  .header-social-button:hover .header-social-icon{
    color: rgb(229,219,194);
  }
  .header-social-button img {
    width: 25px;
    height: auto;
    object-fit: contain;
  }
  
  
  /*
  ====================================================
                   INTRO SECTION
  ====================================================
  */
  
  .intro-main-container{
    width: 100%;
    height: 100%;
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    /*DEBUG*/
    /* background-color: gray; */
  }
  .intro-image-container{
    max-width:350px;
    height: 100%;
    position: relative;
    margin: 20px 5px 20px 20px;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    /*DEBUG*/
    /* background-color: indigo; */
  }
  .intro-image-container img{
    width: 250px;
    height: auto;
    object-fit: cover;
    /* display: flex; */
    margin: auto;
    overflow: hidden;
    position: absolute;
  }
  .intro-image-container video{
    width: 220px;
    height: auto;
    /* background-color: coral; */
  }
  .intro-description-container{
    max-width:640px;
    height: 100%;
    margin: 20px 20px 20px 5px;
    padding: 0px;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    /*DEBUG*/
    /* background-color: indianred; */
  }
  .intro-description-text{
    width: 100%;
    height: auto;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-start;
    /*DEBUG*/
    /* background-color: lightsalmon; */
  }
  .intro-description-text h1{
    margin: 5px 20px;
    padding-top: 10px;
    font-size: 58px;
    font-family: LaudanumSolid;
    font-weight: bolder;
    line-height: 57px;
    color: rgb(34, 34, 34);
  }
  .intro-description-text p{
    margin: 20px 15px;
    padding: 0px;
    font-size: 37px;
    font-family: PreloMedium;
    font-weight: bolder;
    line-height: 44px;
    color: rgb(34, 34, 34);
  }
  
  .intro-description-stores{
    width: calc(100% - 8px);
    height: auto;
    padding-left: 8px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    /*DEBUG*/
    /* background-color: lightblue; */
  }
  .intro-description-stores img{
    width: auto;
    height: 50px;
    margin: 30px 10px 10px 10px;
    cursor: pointer;
  }
  
  .intro-footer-container{
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  /*
  ====================================================
                  PRODUCT SECTION
  ====================================================
  */
  
  .product-conatiner{
    width:100%;
    height: auto;
    background-color: rgb(34,34,34);
  }
  
  .product-main-container{
    width: 100%;
    height: 100%;
    margin: auto ;
    padding-left: 16%;
    padding-right: 16%;
    display: flex;
    justify-content: center;
    align-items: center;
    /*DEBUG*/
    /* background-color: gray; */
  }
  .product-image-container{
    width:250px;
    height: 100%;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    /*DEBUG*/
    /* background-color: indigo; */
  }

  .product-image-container img{
    width: 250px;
    height: 100%;
    object-fit: cover;
    /* display: flex; */
  }
  .product-description-container{
    width: 620px;
    height: 100%;
    padding: 0px;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    /*DEBUG*/
    /* background-color: indianred;  */
  }
  .product-description-text{
    height: 100%;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: flex-start;
    /*DEBUG*/
    /* background-color: lightsalmon; */
  }

  .product-description-top-bar{
    height: auto;
    display: flex;
    /* background-color: lightcoral; */
  }
  


  .product-description-social{
    width: auto;
    height: auto;
    display: flex;
    padding-left: 10px;
    /* background-color: yellowgreen; */
  }
  
  .product-description-social-icon{
    margin: auto;
    font-size: 17px;
    color: rgb(229,219,194);
    transition: all 0.2s linear;
  }
  .product-description-social-button{
    width: 34px;
    height: 34px;
    margin: 6px 0px 0px 10px;
    display: flex;
    border-radius: 50%;
    border: 1px solid rgb(229,219,194);
    cursor: pointer;
    transition: all 0.2s linear;
  }
  .product-description-social-button:hover {
    background-color: rgb(229,219,194);
  }
  .product-description-social-button:hover .product-description-social-icon{
    color: rgb(34, 34, 34);
  }
  .product-description-text h1{
    margin:  0px 20px;
    padding: 0px;
    font-size: 45px;
    font-family: LaudanumSolid;
    font-weight: bolder;
    color: rgb(229,219,194);
    /* background-color: yellowgreen; */

  }
  .product-description-text p{
    margin: 10px 20px;
    padding: 0px;
    font-size: 18px;
    font-family: PreloLight;
    font-weight: bolder;
    line-height: 25px;
    color: rgb(255,255,255);
  }
  
  /*
  ====================================================
                  FOOTER SECTION
  ====================================================
  */
  
  .footer-container{
    width:100%;
    /* height: 200px; */
    padding-bottom: 30px;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
  }
  
  .footer-description-stores{
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    /*DEBUG*/
    /* background-color: lightblue; */
  }
  .footer-description-stores img{
    width: auto;
    height: 50px;
    margin: 20px 10px;
    cursor: pointer;
  }
  
  .footer-description-text h1{
    margin: 50px 20px 10px 20px;
    padding: 0px;
    font-size: 25px;
    font-family: PreloMedium;
    font-weight: bolder;
    line-height: 27px;
    color: rgb(34, 34, 34);
  }
  .footer-politics-conatiner{
    width: 100%;
    height: 40px;
    margin: 5px auto 20px auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .footer-politics-conatiner a{
    margin: 10px 10px;
    padding: 0px;
    font-size: 16px;
    font-family: PreloMedium;
    color: rgb(34, 34, 34);
  }
  
  
  
  
  
  /* :::::: Mouse Scroller ::::::: */
  
  .scroller{
      margin: 15px auto;
      font-size:15px;
      float:left;
      color:rgb(128, 122, 111);
      font-weight:900;
    text-align:center; 
    margin-bottom: 30px;
  }
  
  .scroller a, .scroller a:link{	 
      color:rgb(34, 34, 34) !important;
      font-weight:900;
      text-decoration:none;
  }
  
  .scroller a:hover{	 
      color: rgb(34, 34, 34) !important;
  }
  
  .scroller span{
      margin:0px auto 10px auto;
      width:25px;
      height:35px;
      text-align:center;
      border:2px solid rgb(34, 34, 34);
      display:block;
      -webkit-border-radius: 30px;
      -moz-border-radius: 30px;
      border-radius: 30px;
  }
  
  .scroller span.scroll-down{
      display:block;
      width:4px;
      height:7px;
      background:rgb(34, 34, 34);
      -webkit-border-radius: 30px;
      -moz-border-radius: 30px;
      border-radius: 30px;
      margin:10% auto auto auto;
      
      -webkit-animation: scroll-inner 1s; 
      animation: scroll-inner 1s;
      -webkit-animation-iteration-count: infinite;
      animation-iteration-count: infinite;
      -webkit-animation-timing-function:ease;
      animation-timing-function:ease
        
    }
  
  
  @keyframes scroll-inner {
      from {margin-top: 15%;}
      to {margin-top: 50%;}
  }
  
  @keyframes scroll-mouse {
      from {margin-top: 0;}
      to {margin-top: 15px;}
  }
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  










    /* 
    =================================================
                    UPSCALING (+4K)
    =================================================
    ##Device = Desktops
    ##Screen = 1921px to higher resolution desktops
    */
    @media (min-width: 1921px) {
      .app-container {
        /*DEBUG*/
        /* background-color: lemonchiffon; */
      }
    } 
    
  
    /* 
    =================================================
                        DESKTOPS
    =================================================
    ##Device = Desktops
    ##Screen = 1281px to higher resolution desktops
    */
    @media (min-width: 1281px) and (max-width: 1920px){
      .app-container {
        /*DEBUG*/
        /* background-color: lightblue; */
      }
    } 
    
    
    /* 
    =================================================
                  LAPTOPS AND DESKTOPS
    =================================================
    ##Device = Laptops, Desktops
    ##Screen = B/w 1025px to 1280px
    */
    @media (min-width: 1025px) and (max-width: 1280px) {
      .app-container {
        /*DEBUG*/
        /* background-color: lightcoral; */
      }
    }
    
    
    /* 
    =================================================
               IPAD AND TABLETS (Portrait) 
    =================================================
    ##Device = Tablets, Ipads (portrait)
    ##Screen = B/w 768px to 1024px
    */                                       
    @media (min-width: 768px) and (max-width: 1024px) {
      .app-container {
        /*DEBUG*/
        /* background-color: lightgreen; */
      }
    }
      
    
    /* 
    =================================================
               IPAD AND TABLETS (Landscape) 
    =================================================
    ##Device = Tablets, Ipads (landscape)
    ##Screen = B/w 768px to 1024px
    */               /*768px*/
    @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
      .app-container {
        /*DEBUG*/
        /* background-color: lightseagreen; */
      }
    }
      
      
    /* 
    =================================================
            SMARTPHONE AND TABLETS (Landscape) 
    =================================================
    ##Device = Low Resolution Tablets, Mobiles (Landscape)
    ##Screen = B/w 481px to 767px
    */               /*481px*/               /*768px*/
    @media (min-width: 481px) and (max-width: 768px) and (orientation: landscape) {
      .app-container {
        /*DEBUG*/
        /* background-color: lightslategrey; */
      }
    }
      
  
    /* 
    =================================================
            SMARTPHONE AND TABLETS (PORTRAIT) 
    =================================================
    ##Device = Low Resolution Tablets, Mobiles (PORTRAIT)
    ##Screen = B/w 481px to 767px
    */               /*481px*/               /*768px*/
    @media (min-width: 481px) and (max-width: 768px) {
      .section-container {
        width: 100vw;
        justify-content: flex-start;
        align-items: center;
        height: calc(100vh - 80px) !important;
      }

      .app-container {
        /*DEBUG*/
        /* background-color: olive; */
      }
  
        /*
      ====================================================
                        HEADER
      ====================================================
      */
      .header-container {
        height: 80px;
      }
      /*
      ====================================================
                      INTRO SECTION
      ====================================================
      */
      .intro-image-container{
        width: 100%;
        height: auto;
        position: relative;
        margin: 10px 0px 0px 0px;
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
        /*DEBUG*/
        /* background-color: indigo; */
      }

      .intro-main-container{
        width: 100%;
        height: auto;
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
        /* background-color: tomato; */
      }
      .intro-image-container img{
        width: auto;
        height: 100%;
      }
      .intro-image-container video{
        width: auto;
        display: flex;
        margin: auto;
        height: 88%;
      }
      .intro-description-container{
        width: 100%;
        height: 150px;
        display: flex;
        margin: 0px;
        padding: 0px;
        /* background-color: turquoise; */
      }
      .intro-description-text{
        width: 100%;
        height: auto;
        display: flex;
        margin: 0px;
        padding: 0px;
        /* background-color: lightpink; */
      }
      .intro-description-text h1{
        margin: 20px 20px 10px 20px;
        font-size: 27px;
        line-height: 28px;
        text-align: center;
      }
      .intro-description-text p{
        margin: 5px 8% 5px 8%;
        font-size: 1.2rem;
        line-height: 1.5rem;
        text-align: center;
      }
      .intro-description-stores{
        width: 100%;
        height: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0px;
        margin: 0px;
        /* background-color: lightseagreen; */
      }
      .intro-description-stores img{
        width: auto;
        height: 30px;
        margin: 16px 10px 0px 10px;
      }
  
  
  /*
  ====================================================
                  PRODUCT SECTION
  ====================================================
  */
  /* .product-conatiner{
    height: 100vh;
  } */
  .product-description-container{
    width:100%;
    height: 100%;
    /*DEBUG*/
    /* background-color: indianred; */
  }

  .product-image-container{
    width:100%;
    height: auto;
    /*DEBUG*/
    /* background-color: indigo; */
  }

  .product-main-container{
    height: auto;
    display: flex;
    flex-flow: column-reverse;

    padding-left: 16%;
    padding-right: 16%;
    margin: 0px 50px 50px 0px;
  
  }
  .product-image-container img{
    max-width: 100%;
    height: auto;
    object-fit: contain;
    transform: translateX(27px);
    /* background-color: coral; */

  }

  .product-description-social{
    width: auto !important;
    height: auto !important;
    justify-content: flex-end;
    align-items: center;
    padding: 0px;
    /* background-color: yellowgreen; */
  }
  
  .product-description-text{
    width: auto;
    height: auto;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: flex-start;
    margin: 20px auto;
    /* margin-left: 30px; */
    /*DEBUG*/
    /* background-color: lightsalmon; */
  }
  .product-description-text h1{
    margin: 10px 0px 10px 0px;
    padding-top: 10px;
    font-size: 30px;
    font-family: LaudanumSolid;
    line-height: 32px;
    color: rgb(229,219,194);
    /* background-color: coral; */
  }
  .product-description-text p{
    margin: 5px 0px 0px 0px;
    padding: 0px;
    font-size: 17px;
    font-family: PreloLight;
    font-weight: bolder;
    line-height: 20px;
    color: rgb(255,255,255);
  }
  
  .product-description-social-icon{
    margin: auto;
    font-size: 1rem;
    color: rgb(229,219,194);
    transition: all 0.2s linear;
  }
  .product-description-social-button{
    width: 35px;
    height: 35px;
    margin: 20px 0px 0px 0px;
    display: flex;
    border-radius: 50%;
    border: 1px solid rgb(229,219,194);
    cursor: pointer;
    transition: all 0.2s linear;
  }

  /*
  ====================================================
                  FOOTER SECTION
  ====================================================
  */
  
  .footer-container{
    width:100%;
    height: auto;
    position: relative;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
  }
  
  .footer-description-stores{
    width: calc(100% - 8px);
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .footer-description-stores img{
    width: auto;
    height: 35px;
    margin: 0px 10px 10px 10px;
  }
  
  .footer-description-text h1{
    margin: 20px 40px;
    padding: 0px;
    font-size: 17px;
    font-family: PreloMedium;
    font-weight: bolder;
    line-height: 27px;
    color: rgb(34, 34, 34);
  }
}
  
    /*
    =================================================
            SMARTPHONE AND TABLETS (PORTRAIT) 
    =================================================
    ##Device = Most of the Smartphones Mobiles (Portrait)
    ##Screen = B/w 320px to 479px
    */                                       /*480px*/
    @media (min-width: 320px) and (max-width: 480px) {

      .section-container {
        width: 100vw;
        justify-content: flex-start;
        align-items: center;
        height: calc(100vh - 80px) !important;
      }

      .app-container {
        /*DEBUG*/
        /* background-color: olive; */
      }
  
        /*
      ====================================================
                        HEADER
      ====================================================
      */
      .header-container {
        height: 80px;
      }
      /*
      ====================================================
                      INTRO SECTION
      ====================================================
      */
      .intro-image-container{
        width: 100%;
        height: auto;
        position: relative;
        margin: 10px 0px 0px 0px;
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
        /*DEBUG*/
        /* background-color: indigo; */
      }

      .intro-main-container{
        width: 100%;
        height: auto;
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
        /* background-color: tomato; */
      }
      .intro-image-container img{
        width: auto;
        height: 100%;
      }
      .intro-image-container video{
        width: auto;
        display: flex;
        margin: auto;
        height: 88%;
      }
      .intro-description-container{
        width: 100%;
        height: 150px;
        display: flex;
        margin: 0px;
        padding: 0px;
        /* background-color: turquoise; */
      }
      .intro-description-text{
        width: 100%;
        height: auto;
        display: flex;
        margin: 0px;
        padding: 0px;
        /* background-color: lightpink; */
      }
      .intro-description-text h1{
        margin: 20px 20px 10px 20px;
        font-size: 27px;
        line-height: 28px;
        text-align: center;
      }
      .intro-description-text p{
        margin: 5px 8% 5px 8%;
        font-size: 1.2rem;
        line-height: 1.5rem;
        text-align: center;
      }
      .intro-description-stores{
        width: 100%;
        height: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0px;
        margin: 0px;
        /* background-color: lightseagreen; */
      }
      .intro-description-stores img{
        width: auto;
        height: 30px;
        margin: 16px 10px 0px 10px;
      }
  
  
  /*
  ====================================================
                  PRODUCT SECTION
  ====================================================
  */
  /* .product-conatiner{
    height: 100vh;
  } */
  .product-description-container{
    width:100%;
    height: 100%;
    /*DEBUG*/
    /* background-color: indianred; */
  }

  .product-image-container{
    width:100%;
    height: auto;
    /*DEBUG*/
    /* background-color: indigo; */
  }

  .product-main-container{
    height: auto;
    display: flex;
    flex-flow: column-reverse;

    padding-left: 16%;
    padding-right: 16%;
    margin: 0px 50px 50px 0px;
  
  }
  .product-image-container img{
    max-width: 100%;
    height: auto;
    object-fit: contain;
    transform: translateX(27px);
    /* background-color: coral; */

  }

  .product-description-social{
    width: auto !important;
    height: auto !important;
    justify-content: flex-end;
    align-items: center;
    padding: 0px;
    /* background-color: yellowgreen; */
  }
  
  .product-description-text{
    width: auto;
    height: auto;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: flex-start;
    margin: 20px auto;
    /* margin-left: 30px; */
    /*DEBUG*/
    /* background-color: lightsalmon; */
  }
  .product-description-text h1{
    margin: 10px 0px 10px 0px;
    padding-top: 10px;
    font-size: 30px;
    font-family: LaudanumSolid;
    line-height: 32px;
    color: rgb(229,219,194);
    /* background-color: coral; */
  }
  .product-description-text p{
    margin: 5px 0px 0px 0px;
    padding: 0px;
    font-size: 17px;
    font-family: PreloLight;
    font-weight: bolder;
    line-height: 20px;
    color: rgb(255,255,255);
  }
  
  .product-description-social-icon{
    margin: auto;
    font-size: 1rem;
    color: rgb(229,219,194);
    transition: all 0.2s linear;
  }
  .product-description-social-button{
    width: 35px;
    height: 35px;
    margin: 20px 0px 0px 0px;
    display: flex;
    border-radius: 50%;
    border: 1px solid rgb(229,219,194);
    cursor: pointer;
    transition: all 0.2s linear;
  }

  /*
  ====================================================
                  FOOTER SECTION
  ====================================================
  */
  
  .footer-container{
    width:100%;
    height: auto;
    position: relative;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
  }
  
  .footer-description-stores{
    width: calc(100% - 8px);
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .footer-description-stores img{
    width: auto;
    height: 35px;
    margin: 0px 10px 10px 10px;
  }
  
  .footer-description-text h1{
    margin: 20px 40px;
    padding: 0px;
    font-size: 17px;
    font-family: PreloMedium;
    font-weight: bolder;
    line-height: 27px;
    color: rgb(34, 34, 34);
  }
}



.comingsoon{
    margin: 20px auto;
    padding: 0px;
    font-size: 17px;
    font-family: PreloMedium;
    text-align: center;
    color: rgb(34, 34, 34);
}









.header-social-button .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: rgb(34, 34, 34);
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 150%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}

.header-social-button .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: rotate(180deg);
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: rgb(34, 34, 34) transparent transparent transparent;
}

.header-social-button:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}